<template>
  <div style="display: inline-block; width: 30px;">
    <i @click="showModal = true" style="cursor: pointer;" class="fa fa-edit"></i>
    <b-modal
      v-model="showModal"
      size="xl"
      @hidden="resetForms()"
      hide-footer
    >
      <b-row class="mb-3">
        <b-col></b-col>
        <b-col class="text-center">
          <h2>
            <b-badge variant="primary" class="carReg">{{ editingVehicleReg }}</b-badge>
          </h2>
        </b-col>
        <b-col class="text-right">
          <b-button
            @click="deleteVehicle()"
            :disabled="!confirmVehicleDelete"
            pill
            variant="outline-danger"
            size="sm"
            style="margin-top: 5px;"><b-icon icon="trash"></b-icon> Delete Vehicle</b-button>
          <b-form-checkbox v-model="confirmVehicleDelete">
            Confirm
          </b-form-checkbox>
        </b-col>
      </b-row>

      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-1
              variant="outline-primary">Vehicle</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" v-model="accordianVehicle" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-make">Make</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-make"
                          v-model="vehicleMake"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-model">Model</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-model"
                          v-model="vehicleModel"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-variant">Variant</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-variant"
                          v-model="vehicleVariant"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-body-type">Body Type</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-body-type"
                          v-model="vehicleBodyType"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-colour">Colour</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-colour"
                          v-model="vehicleColour"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-seats">Seats</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-seats"
                          type="number"
                          v-model.number="vehicleSeats"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-engine-size">Engine Size</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-engine-size"
                          type="number"
                          v-model.number="vehicleEngineSize"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-fuel-type">Fuel Type</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-fuel-type"
                          v-model="vehicleFuelType"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-power">Power (BHP)</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-power"
                          type="number"
                          v-model.number="vehiclePower"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-transmission">Transmission</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-transmission"
                          v-model="vehicleTransmission"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-registration-date">First Registration</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-registration-date"
                          v-model="vehicleRegistrationDate"
                          placeholder="DD/MM/YYYY"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-tax-band">Tax Band</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-tax-band"
                          v-model="vehicleTaxBand"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-mileage">Mileage</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-mileage"
                          type="number"
                          v-model.number="vehicleMileage"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                  </b-col>

                  <b-col class="text-center">
                    <img v-if="vehiclePhotos.length" style="width: 400px;" :src="vehiclePhotos[0].url" />
                  </b-col>
                </b-row>

              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-2
              variant="outline-primary"
              :disabled="!vehicleStepCheck">Price</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>

                <b-row>
                  <b-col>
                    <b-row>
                      <b-col class="text-right mt-1">
                        <label for="vehicle-price">Asking Price</label>
                      </b-col>
                      <b-col>
                        <b-input-group>
                          <b-input-group-prepend>
                            <span class="input-group-text">£</span>
                          </b-input-group-prepend>
                          <b-form-input
                            id="vehicle-price"
                            type="number"
                            v-model.number="vehiclePrice"></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>

              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-3
              variant="outline-primary"
              :disabled="!vehicleStepCheck || !priceStepCheck">Advert</b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-title">Title</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="advert-title"
                      v-model="advertTitle"
                      size="sm"></b-form-input>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-subtitle">Subtitle</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="advert-subtitle"
                      v-model="advertSubtitle"
                      size="sm"></b-form-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-tag">Tag</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="advert-tag"
                      v-model="advertTag"
                      maxlength="15"
                      size="sm"></b-form-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-description">Description</label>
                  </b-col>
                  <b-col>
                    <b-form-textarea
                      id="advert-description"
                      v-model="advertDescription"
                      size="sm"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-col>
                </b-row>

                <b-row class="mt-3 mb-3">
                  <b-col class="text-right" cols="3">
                    <label for="advert-features">Features</label>
                  </b-col>
                  <b-col>
                    <b-badge v-if="!vehicleEquipmentList" variant="warning">Features unavailable</b-badge>
                    <template v-else>
                      <b-badge
                        v-for="(feature, index) in vehicleEquipmentSelectedSorted"
                        :key="index"
                        style="margin-right: 5px;"
                        pill
                        variant="primary">{{ feature.Name }}</b-badge>
                      <b-badge @click="showFeaturesModal=true" variant="warning" pill style="cursor: pointer;">Add / Remove</b-badge>
                    </template>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-owners">Owners</label>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input
                      id="advert-owners"
                      type="number"
                      v-model.number="vehicleOwners"
                      size="sm"></b-form-input>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-service-history">Service History</label>
                  </b-col>
                  <b-col cols="2">
                    <b-form-select
                      id="advert-service-history"
                      v-model="vehicleServiceHistory"
                      :options="serviceHistoryOptions"
                      size="sm"></b-form-select>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-mot-due">MOT Due</label>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input
                      id="advert-mot-due"
                      v-model="vehicleMotDue"
                      placeholder="DD/MM/YYYY"
                      size="sm"></b-form-input>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>

              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-4
              variant="outline-primary"
              :disabled="!vehicleStepCheck || !priceStepCheck || !advertStepCheck">Publish / Unpublish</b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col class="text-right">
                    <b-form-checkbox v-model="vehicleFeatured" :disabled="(!vehicleActive)">
                      Featured
                    </b-form-checkbox>
                  </b-col>
                  <b-col class="text-left">
                    <b-form-checkbox v-model="vehicleActive" :disabled="vehicleFeatured">
                      Publish
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center mt-3">
                    <b-button @click="saveAdvert" variant="primary">Save</b-button>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-modal>

    <!-- Features modal -->
    <b-modal
      v-model="showFeaturesModal"
      size="lg"
      hide-footer
    >
      <template v-if="vehicleEquipmentList">
        <b-row v-for="(category, index) in vehicleEquipmentCategories" :key="index">
          <b-col cols="3" class="text-right">
            <label>{{ category }}</label>
          </b-col>
          <b-col>
            <b-badge
              v-for="(equipment, index) in getCategoryEquipment(category)"
              :key="index"
              style="cursor: pointer; margin-right: 5px;"
              pill
              @click="addOrRemoveEquipment(equipment)"
              :variant="checkEquipmentAdded(equipment)">{{ equipment.Name }}</b-badge>
          </b-col>
        </b-row>
      </template>
    </b-modal>

  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditStock',
  props: {
    editingVehicleReg: {
      default: () => (''),
      type: String
    },
  },
  data() {
    return {
      showModal: false,
      showFeaturesModal: false,
      accordianVehicle: true,

      confirmVehicleDelete: false,

      timestampFormat: 'YYYY-MM-DDTHH:mm:ss',

      vehicleMake: '',
      vehicleModel: '',
      vehicleVariant: '',
      vehicleBodyType: '',
      vehicleFuelType: '',
      vehicleColour: '',
      vehicleTransmission: '',
      vehicleEngineSize: '',
      vehiclePower: '',
      vehicleSeats: '',
      vehicleRegistrationDate: '',
      vehicleTaxBand: '',
      vehicleMileage: '',
      vehicleStockImage: '',

      vehicleValuation: null,
      vehiclePrice: '',

      advertTitle: '',
      advertSubtitle: '',
      advertTag: '',
      advertDescription: '',
      vehicleEquipmentList: null,
      vehicleEquipmentSelected: [],
      vehicleOwners: '',
      vehicleServiceHistory: '',
      vehicleMotDue: '',
      serviceHistoryOptions: [
        'Full service history',
        'Part service history',
        'No service history',
        'First service not due'
      ],

      vehiclePhotos: [],

      vehicleActive: false,
      vehicleFeatured: false,
      
    }
  },
  computed: {
    ...mapGetters('stock', ['getAllVehicles', 'getFeaturedVehicles']),

    vehicle() {
      const i = this.getAllVehicles.findIndex(v => v.vehicle.reg === this.editingVehicleReg)
      if (i > -1) {
        return this.getAllVehicles[i]
      } else {
        return null
      }
    },

    vehicleStepCheck() {
      return this.vehicleMake &&
        this.vehicleModel &&
        this.vehicleVariant &&
        this.vehicleBodyType &&
        this.vehicleFuelType &&
        this.vehicleColour &&
        this.vehicleTransmission &&
        this.vehicleEngineSize &&
        this.vehiclePower &&
        this.vehicleSeats &&
        this.vehicleRegistrationDate &&
        this.vehicleTaxBand &&
        this.vehicleMileage
      ? true : false
    },
    priceStepCheck() {
      return this.vehiclePrice ? true : false
    },
    advertStepCheck() {
      return this.advertTitle &&
        this.advertSubtitle &&
        this.advertDescription &&
        this.vehicleServiceHistory &&
        this.vehicleMotDue
      ? true : false
    },
    publishStepCheck() {
      return this.priceStepCheck && this.vehicleStepCheck && this.advertStepCheck
    },

    vehicleEquipmentCategories() {
      return this.vehicleEquipmentList ? Array.from(new Set(this.vehicleEquipmentList.map((item) => item.Category))).sort() : null
    },
    vehicleEquipmentSelectedSorted() {
      return this.vehicleEquipmentSelected.sort((a, b) => { return a.Name > b.Name ? 1 : -1 })
    }
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.resetForms()
      }
    },

    vehicleMake() {
      this.vehicleMake = this.vehicleMake.toUpperCase()
    },
    vehicleModel() {
      this.vehicleModel = this.vehicleModel.toUpperCase()
    },
    vehicleVariant() {
      this.vehicleVariant = this.vehicleVariant.toUpperCase()
    },
    vehicleBodyType() {
      this.vehicleBodyType = this.vehicleBodyType.toUpperCase()
    },
    vehicleFuelType() {
      this.vehicleFuelType = this.vehicleFuelType.toUpperCase()
    },
    vehicleColour() {
      this.vehicleColour = this.vehicleColour.toUpperCase()
    },
    vehicleTransmission() {
      this.vehicleTransmission = this.vehicleTransmission.toUpperCase()
    },
    vehicleTaxBand() {
      this.vehicleTaxBand = this.vehicleTaxBand.toUpperCase()
    },
  },
  methods: {
    ...mapActions('stock', [
      'getVehicleValuationFromReg',
      'getVehicleStockImageFromReg',
      'getVehicleSpecOptionsFromReg',
      'dbUpdateVehicle',
      'dbDeleteVehicle'
    ]),

    resetForms() {
      this.accordianVehicle = true,
      this.confirmVehicleDelete = false

      this.vehicleMake = this.vehicle.vehicle.make
      this.vehicleModel = this.vehicle.vehicle.model
      this.vehicleVariant = this.vehicle.vehicle.variant
      this.vehicleBodyType = this.vehicle.vehicle.bodyType
      this.vehicleFuelType = this.vehicle.vehicle.fuelType
      this.vehicleColour = this.vehicle.vehicle.colour
      this.vehicleTransmission = this.vehicle.vehicle.transmission
      this.vehicleEngineSize = this.vehicle.vehicle.engine
      this.vehiclePower = this.vehicle.vehicle.power
      this.vehicleSeats = this.vehicle.vehicle.seats
      this.vehicleRegistrationDate = moment(this.vehicle.vehicle.registrationDate).format('DD/MM/YYYY')
      this.vehicleTaxBand = this.vehicle.vehicle.taxBand
      this.vehicleMileage = this.vehicle.vehicle.mileage

      this.vehicleValuation = null
      this.vehiclePrice = this.vehicle.advert.price

      this.advertTitle = this.vehicle.advert.title
      this.advertSubtitle = this.vehicle.advert.subtitle
      this.advertTag = this.vehicle.advert.tag ? this.vehicle.advert.tag : ''
      this.advertDescription = this.vehicle.advert.description
      this.vehicleEquipmentList = this.vehicle.equipmentList
      this.vehicleEquipmentSelected = this.vehicle.advert.features
      this.vehicleOwners = this.vehicle.vehicle.owners
      this.vehicleServiceHistory = this.vehicle.advert.serviceHistory
      this.vehicleMotDue = moment(this.vehicle.vehicle.motDue).format('DD/MM/YYYY')

      this.vehiclePhotos = this.vehicle.images

      this.vehicleActive = this.vehicle.active
      this.vehicleFeatured = this.vehicle.featured
    },

    getCategoryEquipment(category) {
      return this.vehicleEquipmentList.filter(equipment => equipment.Category === category)
    },
    addOrRemoveEquipment(equipment) {
      const eIndex = this.vehicleEquipmentSelected.findIndex(ves =>
        ves.Category === equipment.Category &&
        ves.Description === equipment.Description &&
        ves.Name === equipment.Name
      )
      if (eIndex > -1) { // Remove
        this.vehicleEquipmentSelected.splice(eIndex, 1)
      } else { // Add
        this.vehicleEquipmentSelected.push(equipment)
      }
    },
    checkEquipmentAdded(equipment) {
      const eIndex = this.vehicleEquipmentSelected.findIndex(ves =>
        ves.Category === equipment.Category &&
        ves.Description === equipment.Description &&
        ves.Name === equipment.Name
      )
      if (eIndex > -1) return 'primary'
      return 'light'
    },

    deleteVehicle() {
      this.dbDeleteVehicle(this.vehicle)
        .then(() => {
          this.showModal = false
        })
        .catch(err => {
          console.error(err)
        })
    },

    saveAdvert() {
      console.log(moment(this.vehicleMotDue, 'DD/MM/YYYY').format(this.timestampFormat))
      const payload = {
        docId: this.vehicle.id,
        data: {
          active: this.vehicleActive,
          updated: moment().format(this.timestampFormat),
          featured: this.vehicleFeatured,
          vehicle: {
            reg: this.editingVehicleReg,
            make: this.vehicleMake,
            model: this.vehicleModel,
            variant: this.vehicleVariant,
            bodyType: this.vehicleBodyType,
            fuelType: this.vehicleFuelType,
            colour: this.vehicleColour,
            transmission: this.vehicleTransmission,
            engine: parseInt(this.vehicleEngineSize),
            power: parseInt(this.vehiclePower),
            seats: this.vehicleSeats,
            registrationDate: moment(this.vehicleRegistrationDate, 'DD/MM/YYYY').format(this.timestampFormat),
            taxBand: this.vehicleTaxBand,
            mileage: parseInt(this.vehicleMileage),
            motDue: moment(this.vehicleMotDue, 'DD/MM/YYYY').format(this.timestampFormat),
            owners: parseInt(this.vehicleOwners)
          },
          advert: {
            price: parseInt(this.vehiclePrice),
            title: this.advertTitle,
            subtitle: this.advertSubtitle,
            tag: this.advertTag,
            description: this.advertDescription,
            features: this.vehicleEquipmentSelected,
            serviceHistory: this.vehicleServiceHistory,
          }
        }
      }
      this.dbUpdateVehicle(payload)
        .then(() => {
          this.showModal = false
        })
        .catch(err => {
          console.error(err)
        })
    }

  }
}
</script>

<style scoped>
.carReg {
  font-family: 'UKNumberPlate';
  font-size: 2em;
  background-color: #FFD900;
  color: #000;
}
#vehicle-reg {
  margin-right: 10px;
}

</style>
